import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { camelizeKeys } from "humps"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"

export default defineNuxtRouteMiddleware((to) => {
  const cameraStore = useCameraStore()
  const accountStore = useAccountStore()
  const projectStore = useProjectStore()
  const { cameraExid, projectExid } = camelizeKeys(to.params)

  const cameraRoute = `/v2/projects/${projectExid}/${cameraExid}`

  const areCameraAndProjectFetched =
    cameraStore.selectedCamera && projectStore.selectedProject

  const areSameProject =
    cameraStore.selectedCamera?.project?.id === projectStore.selectedProjectExid
  if (
    (!areSameProject || !areCameraAndProjectFetched) &&
    accountStore.token &&
    !cameraStore.isCameraPublic
  ) {
    return navigateTo(`${cameraRoute}/404`)
  }

  //Custom case for projects with no cameras. TODO// DELETE WHEN Projects supports no cameras.
  if (
    projectStore.selectedProject?.featureFlags?.includes(
      ProjectFeatureFlag.NoCameras
    )
  ) {
    const featureFlags = projectStore.selectedProject.featureFlags
    let targettedApp = ""

    if (featureFlags.includes(ProjectFeatureFlag.DroneView)) {
      targettedApp = "drone"
    } else if (featureFlags.includes(ProjectFeatureFlag.ThreeSixtyView)) {
      targettedApp = "360"
    } else if (featureFlags.includes(ProjectFeatureFlag.Bim)) {
      targettedApp = "bim"
    } else if (
      featureFlags.includes(ProjectFeatureFlag.GateReport) ||
      featureFlags.includes(ProjectFeatureFlag.GateReportAnprBased)
    ) {
      targettedApp = "gate-report"
    }
    if (!targettedApp) {
      return
    }

    const projectFeatureRoute = `${projectStore?.projectRoute}/${targettedApp}`

    return navigateTo(`${projectFeatureRoute}`)
  }
})
