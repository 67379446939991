import { NotificationType } from "@evercam/shared/types/notification"
import { CameraStatus } from "@evercam/shared/types/camera"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export const notificationFormatterMixin = {
  methods: {
    getCameraName(cameraExid) {
      const camera = useCameraStore().getCameraByExid(cameraExid)

      return camera?.name || ""
    },

    getCameraThumbnailUrl(cameraExid) {
      if (!cameraExid) {
        return
      }

      return this.$imgproxy.get360pResizedImageUrl(
        `${
          this.$config.public.apiURL
        }/cameras/${cameraExid}/thumbnail?authorization=${
          useAccountStore().token
        }`
      )
    },

    getProjectName(exid) {
      const project = useProjectStore().getProjectByExid(exid)

      return project?.name || ""
    },

    getCameraShareNotification(notification) {
      const title = `<b>${notification.initiatorFullname}</b> ${this.$t(
        "content.notifications.shared_camera_with_you"
      )}`
      const thumbnail = this.getCameraThumbnailUrl(
        notification.context?.cameraExid
      )
      const link = `/v2/projects/${notification.context.projectExid}/${notification.context.cameraExid}`

      return { title, thumbnail, link }
    },

    getCameraStatusChangeNotification(notification) {
      const cameraName = this.getCameraName(notification.context.cameraExid)
      const newStatus = notification.context.newStatus

      let title = ""
      if (newStatus === CameraStatus.Offline) {
        title = this.$t("content.notifications.camera_went_offline", {
          cameraName,
        })
      } else if (newStatus === CameraStatus.Online) {
        title = this.$t("content.notifications.camera_came_online", {
          cameraName,
        })
      } else {
        title = this.$t("content.notifications.camera_status_change_generic", {
          cameraName,
          status: newStatus,
        })
      }

      const thumbnail = this.getCameraThumbnailUrl(
        notification.context?.cameraExid
      )
      const link = `/v2/projects/${notification.context?.projectExid}/${notification.context.cameraExid}`

      return { title, thumbnail, link }
    },

    getMentionNotification(notification) {
      const title = `${notification.initiatorFullname} mentioned you in a post`
      const icon = "fa fa-at"
      const thumbnail = this.getCameraThumbnailUrl(
        notification.context?.cameraExid
      )
      const link = ""

      return { title, thumbnail, icon, link }
    },

    getTimelapseCreatedNotification(notification) {
      const cameraName = this.getCameraName(notification.context.cameraExid)
      const title = this.$t("content.notifications.timelapsed_created", {
        cameraName,
      })
      const thumbnail = this.getCameraThumbnailUrl(
        notification.context?.cameraExid
      )
      const link = `/v2/projects/${notification.context.projectExid}/media-hub/${notification.context.archiveExid}?camera_exid=${notification.context.cameraExid}`

      return { title, thumbnail, link }
    },

    getTimelapseFailedNotification(notification) {
      const cameraName = this.getCameraName(notification.context.cameraExid)
      const title = this.$t("content.notifications.timelapsed_failed", {
        cameraName,
      })
      const thumbnail = this.getCameraThumbnailUrl(
        notification.context?.cameraExid
      )
      const link = `/v2/projects/${notification.context.projectExid}/${notification.context.cameraExid}/timelapse/`

      return { title, thumbnail, link }
    },

    getDroneProcessingNotification(notification) {
      const projectName = this.getProjectName(notification.context.projectExid)
      const title = this.$t("content.notifications.drone_processing_done", {
        projectName,
        droneFlightDate: notification.context.droneDate,
      })

      const link = `/v2/projects/${notification.context.projectExid}/drone?date=${notification.context.droneDate}`

      return { title, link, drone: true }
    },

    get360ProcessingNotification(notification) {
      const projectName = this.getProjectName(notification.context.projectExid)
      const title = this.$t("content.notifications.processing_360_done", {
        projectName,
        date: notification.context.date,
        floor: notification.context.floor,
      })
      const link = `/v2/projects/${notification.context.projectExid}/360?floor=${notification.context.floor}`

      return { title, link, is360: true }
    },

    formatNotification(notification) {
      let formattedNotification = {}

      switch (notification.type) {
        case NotificationType.CameraShare:
          formattedNotification = this.getCameraShareNotification(notification)
          break
        case NotificationType.CameraStatusChange:
          formattedNotification =
            this.getCameraStatusChangeNotification(notification)
          break
        case NotificationType.Mention:
          formattedNotification = this.getMentionNotification(notification)
          break
        case NotificationType.TimelapseCreated:
          formattedNotification =
            this.getTimelapseCreatedNotification(notification)
          break
        case NotificationType.TimelapseFailed:
          formattedNotification =
            this.getTimelapseFailedNotification(notification)
          break
        case NotificationType.DroneProcessing:
          formattedNotification =
            this.getDroneProcessingNotification(notification)
          break
        case NotificationType.Processing360:
          formattedNotification =
            this.get360ProcessingNotification(notification)
          break
        default:
          formattedNotification = {
            title: "",
            icon: "fa fa-question",
            thumbnail: "/unavailable.jpg",
            link: "",
          }
      }

      const date = this.$moment(notification.insertedAt).fromNow()
      const read = notification.readTimestamp !== null

      return { ...formattedNotification, date, read, id: notification.id }
    },
  },
}
