import { useAccountStore } from "@/stores/account"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"

export default defineNuxtRouteMiddleware((to) => {
  const accountStore = useAccountStore()
  if (accountStore.token === null) {
    return
  }
  if (to.path.includes("/password-new")) {
    accountStore.logout()
  } else if (to.query.serviceurl) {
    const user = {
      email: accountStore.email,
      firstname: accountStore.firstname,
      lastname: accountStore.lastname,
    }
    accountStore.zohoLogin(user)
  } else if (!to.path.includes("/widgets/")) {
    return navigateTo("/v2/projects")
  }
})
