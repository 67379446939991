import { UnitSystem } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { defineNuxtRouteMiddleware, useNuxtApp } from "#app"

export default defineNuxtRouteMiddleware(async () => {
  const accountStore = useAccountStore()
  if (accountStore.isImpersonationLogin) {
    return
  }

  let country = accountStore.country
  if (
    !accountStore.unitSystem &&
    (localStorage?.getItem("localUnitSystem") || country)
  ) {
    if (localStorage?.getItem("localUnitSystem")) {
      accountStore.unitSystem = localStorage?.getItem("localUnitSystem")
    } else if (country === "us") {
      useNuxtApp().nuxt2Context.$units.selectUnits(UnitSystem.Imperial)
      accountStore.unitSystem = UnitSystem.Imperial
    } else {
      useNuxtApp().nuxt2Context.$units.selectUnits(UnitSystem.Metric)
      accountStore.unitSystem = UnitSystem.Metric
    }
  }
})
