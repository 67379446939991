import { defineNuxtRouteMiddleware, navigateTo } from "#app"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useNuxtApp } from "#app"

export default defineNuxtRouteMiddleware(async (to, _) => {
  // if archive is deleted or not found, redirect to media hub
  // route: https://dash.evercam.io/v2/projects/007pr-zqhay/007pr-idxlw/archives/everc-iygpt to /v2/projects/007pr-zqhay/media-hub/everc-iygpt?camera_exid=007pr-idxlw
  if (to.path.includes("/v2/projects/") && to.path.includes("/archives/")) {
    const splitted = to.path.split("/")
    const projectExid = splitted[3]
    const mediaExid = splitted[6]
    const cameraExid = splitted[4]
    try {
      await EvercamApi.mediaHub.cShow(projectExid, mediaExid)

      return navigateTo(
        `/v2/projects/${projectExid}/media-hub/${mediaExid}?camera_exid=${cameraExid}`
      )
    } catch (error) {
      console.error("Error fetching archive", error)
      useNuxtApp().nuxt2Context.$notifications.error({
        text: useNuxtApp().vue2App.$i18n.t("content.media_hub.clip_not_found"),
      })

      return navigateTo({ path: `/v2/projects/${projectExid}/media-hub` })
    }
    // redirect to media hub if we are still using old archive link
    // for test: https://dash.evercam.io/v2/cameras/007pr-ifboe/archives/everc-idnkg

    // deleted archive: https://dash.evercam.io/v2/projects/007pr-zqhay/007pr-idxlw/archives/everc-iygpt
  }
})
