import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"

export default defineNuxtRouteMiddleware(async (to) => {
  const cameraStore = useCameraStore()
  const accountStore = useAccountStore()
  if (accountStore.isWidget) {
    return
  }
  // old home page -> new home page
  if (accountStore.token && to.path === "/v2/cameras") {
    return navigateTo("/v2/projects")
  }

  // Redirect /v2/projects/{projectExid}/team to /v2/projects/{projectExid}/settings/members
  const projectExidRegex = new RegExp("/v2/projects/([a-zA-Z0-9_.-]+)/team")
  const projectExid = (projectExidRegex.exec(to.path) || [])[1]
  if (accountStore.token && projectExid) {
    return navigateTo(`/v2/projects/${projectExid}/settings/members`)
  }

  // Backwards compatibility for all /v2/cameras/camera_id/... pages
  const cameraExidRegex = new RegExp("/v2/cameras/([a-zA-Z0-9_.-]+)")
  const cameraExid = (cameraExidRegex.exec(to.path) || [])[1]
  if (accountStore.token && cameraExid) {
    if (!cameraStore.cameras?.length) {
      await cameraStore.fetchUserCameras()
    }
    const projectId = cameraStore.getCameraByExid(cameraExid)?.project?.id

    if (!projectId) {
      return navigateTo("/v2/projects")
    } else {
      const newRoute = to.fullPath
        .replace("/v2/cameras", `/v2/projects/${projectId}`)
        .replace(/api_id=[a-zA-Z0-9]+&api_key=[a-zA-Z0-9]+/g, "")

      return navigateTo(newRoute)
    }
  } else if (
    cameraExid &&
    !accountStore.token &&
    !/(authorization|api_id|api_key)/.test(to.fullPath)
  ) {
    accountStore.redirectUrl = to.fullPath

    return navigateTo("/v2/users/signin")
  }
})
