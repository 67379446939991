
import TheRightSidebar from "@/components/TheRightSidebar"
export default {
  components: {
    TheRightSidebar,
  },
  mounted() {
    this.$setTimeout(() => (this.$vuetify.theme.dark = false), 0)
  },
}
