
import Vue from "vue"
import TheSnackbar from "@/components/TheSnackbar"
export default Vue.extend({
  components: {
    TheSnackbar,
  },
  head() {
    return {
      meta: [
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, maximum-scale=1",
        },
      ],
    }
  },
  mounted() {
    this.$setTimeout(() => (this.$vuetify.theme.dark = false), 0)
  },
})
