import { useAccountStore } from "@/stores/account"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"

export default defineNuxtRouteMiddleware((to) => {
  const accountStore = useAccountStore()
  if (accountStore.isWidget) {
    return
  }
  // old snapmails -> new progress-photos
  if (accountStore.token && to.path === "/v2/snapmails") {
    return navigateTo("/v2/automations/progress-photos")
  }
  // old snapmails -> new progress-photos
  if (accountStore.token && to.path === "/v2/automations") {
    return navigateTo("/v2/automations/progress-photos")
  }
})
